import React from "react"
import { ThemeToggler } from "gatsby-plugin-dark-mode"

const DarkModeToggle = () => {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <div
          className="dark-mode-trigger"
          onClick={() => toggleTheme(theme === "dark" ? "light" : "dark")}
        >
          {/* {theme === "dark" ? "Light" : "Dark"} */}
          <svg
            className="sun"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M.75 20.957a.75.75 0 0 1 0-1.5h22.5a.75.75 0 0 1 0 1.5H.75z"></path>
            <path d="M12 5.957a.75.75 0 0 1-.75-.75v-1.5a.75.75 0 0 1 1.5 0v1.5a.75.75 0 0 1-.75.75z"></path>
            <path d="M18.894 8.812a.75.75 0 0 1-.531-1.28l1.061-1.06c.142-.142.33-.219.531-.219s.389.078.531.22a.75.75 0 0 1 0 1.06l-1.061 1.06a.752.752 0 0 1-.531.219z"></path>
            <path d="M21.75 15.707a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5h-1.5z"></path>
            <path d="M.75 15.707a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5H.75z"></path>
            <path d="M5.106 8.812a.743.743 0 0 1-.53-.22l-1.061-1.06a.746.746 0 0 1 .529-1.28c.2 0 .389.078.53.22l1.061 1.06a.75.75 0 0 1-.529 1.28z"></path>
            <path d="M18.337 17.957a.744.744 0 0 1-.681-.435.74.74 0 0 1-.023-.573 5.963 5.963 0 0 0-.188-4.588 5.962 5.962 0 0 0-3.378-3.111 5.958 5.958 0 0 0-2.06-.367 6.026 6.026 0 0 0-5.64 3.934 6.005 6.005 0 0 0 0 4.133.75.75 0 0 1-.704 1.008.753.753 0 0 1-.704-.492 7.509 7.509 0 0 1 0-5.166 7.532 7.532 0 0 1 7.049-4.917 7.45 7.45 0 0 1 2.576.459 7.45 7.45 0 0 1 4.222 3.889 7.45 7.45 0 0 1 .236 5.735.756.756 0 0 1-.705.491z"></path>
          </svg>

          <svg
            className="moon"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M15.937 23.962c-1.216 0-2.426-.187-3.596-.554a11.918 11.918 0 0 1-7.042-5.896 11.926 11.926 0 0 1-.811-9.149 11.921 11.921 0 0 1 5.896-7.042 12.022 12.022 0 0 1 5.539-1.368c1.218 0 2.433.187 3.609.557a.747.747 0 0 1 .525.715.748.748 0 0 1-.525.716 10.462 10.462 0 0 0-7.351 10.018 10.462 10.462 0 0 0 7.351 10.017c.314.099.525.386.525.715s-.211.617-.525.715a11.932 11.932 0 0 1-3.595.556zm-.013-22.508c-1.67 0-3.345.414-4.846 1.197A10.43 10.43 0 0 0 5.92 8.813c-.841 2.676-.589 5.519.709 8.005s3.486 4.318 6.162 5.159a10.49 10.49 0 0 0 4.013.449 11.987 11.987 0 0 1-6.122-10.466 11.987 11.987 0 0 1 6.124-10.468 9.99 9.99 0 0 0-.882-.038z"></path>
          </svg>
        </div>
      )}
    </ThemeToggler>
  )
}
export default DarkModeToggle
